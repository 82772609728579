$(document).ready(function () {
  $("#show_more").click(function (e) {
    e.preventDefault();
    $(".hiden_camera").show();
    $("#show_more").hide();
    AOS.init();
  });
  $(".searchForm input").keyup(function () {
    var val = $(this).val();
    $(".searchForm").attr("action", "/diffuseurs?search=" + val + "");
  });
  var location = window.location.href;
  $(".nav-link").each(function () {
    if ($(this).attr("href")) {
      if ($(this).attr("href").indexOf(location) > -1 && location.length > 33) {
        $(this).addClass("active");
      }
    }
  });
  $("#applyDiscountCode").click(function () {
    let code = $("#inputDiscount").val();
    $.ajax({
      method: "POST",
      url: "/transactions/applydiscount",
      data: { code: code },
    }).done(function (coupon) {
      if (coupon != false) {
        let modulo = coupon.type == "%" ? true : false;
        let oneDay = $("#oneDay").text();
        let oneWeek = $("#oneWeek").text();
        let oneMonth = $("#oneMonth").text();
        let threeMonth = $("#threeMonth").text();
        let oneDayDiscount = checkNegativeValue(oneDay, coupon.amount, modulo);
        let oneWeekDiscount = checkNegativeValue(
          oneWeek,
          coupon.amount,
          modulo
        );
        let oneMonthDiscount = checkNegativeValue(
          oneMonth,
          coupon.amount,
          modulo
        );
        let threeMonthDiscount = checkNegativeValue(
          threeMonth,
          coupon.amount,
          modulo
        );
        $("#oneDay").html("<strike>" + oneDay + "$</strike> " + oneDayDiscount);
        $("#oneWeek").html(
          "<strike>" + oneWeek + "$</strike> " + oneWeekDiscount
        );
        $("#oneMonth").html(
          "<strike>" + oneMonth + "$</strike> " + oneMonthDiscount
        );
        $("#threeMonth").html(
          "<strike>" + threeMonth + "$</strike> " + threeMonthDiscount
        );
        $("#oneDayBtn").attr(
          "href",
          buildDiscountHref($("#oneDayBtn").attr("href"), oneDayDiscount)
        );
        $("#oneWeekBtn").attr(
          "href",
          buildDiscountHref($("#oneWeekBtn").attr("href"), oneWeekDiscount)
        );
        $("#oneMonthBtn").attr(
          "href",
          buildDiscountHref($("#oneMonthBtn").attr("href"), oneMonthDiscount)
        );
        $("#threeMonthBtn").attr(
          "href",
          buildDiscountHref(
            $("#threeMonthBtn").attr("href"),
            threeMonthDiscount
          )
        );
        $("#discountForm").hide();
        $("#successMessage").show();
      } else {
        $("#errorMessage").show();
      }
    });
  });
  $(".language_switcher").click(function (e) {
    e.preventDefault();
    let lang = $(this).data("lang");
    localStorage.setItem("lang", lang);
    let path = window.location.pathname;
    let currentLang = path.split("/")[1];
    if (window.location.href.indexOf("broadcaster") != -1) {
      let parameters = window.location.href.split("?")[1];
      if (currentLang != "en" && lang == "en") {
        window.location.href = "/" + lang + path + "?" + parameters;
      } else if (currentLang == "en" && lang != "en") {
        let splited = path.split("/");
        window.location.href = "/"+splited[2]+"/"+splited[3]+"?" + parameters;
      }
    } else if (path.split("/")[1] != "broadcaster" && currentLang != lang) {
      if (lang == "en") {
        path = path.replace("fr-ca", "en");
        path = path.replace("diffuseurs", "broadcasters");
        path = path.replace("nous-joindre", "contact_us");
        path = path.replace("a-propos", "about-us");
        path = path.replace("politiques-de-confidentialite", "privacy-policy");
        path = path.replace(
          "conditions-d-utilisation",
          "terms-and-conditions-of-use"
        );
        path = path.replace("conditions-d-achats", "purchase-condition");
        path = path.replace("compte", "my-account");
      } else {
        path = path.replace("en", "fr-ca");
        path = path.replace("broadcasters", "diffuseurs");
        path = path.replace("contact_us", "nous-joindre");
        path = path.replace("about-us", "a-propos");
        path = path.replace("privacy-policy", "politiques-de-confidentialite");
        path = path.replace(
          "terms-and-conditions-of-use",
          "conditions-d-utilisation"
        );
        path = path.replace("purchase-condition", "conditions-d-achats");
        path = path.replace("my-account", "compte");
      }
      window.location.href = path;
    }
    return;
  });
});
var checkNegativeValue = function (value, amount, modulo) {
  let sum = 0;
  if (modulo) {
    let percentage = (value * amount) / 100;
    sum = value - percentage;
  } else {
    sum = value - amount;
  }
  if (sum <= 0) {
    sum = 0.0;
  } else {
    sum = sum;
  }
  return sum.toFixed(2);
};
var buildDiscountHref = function (href, discount) {
  var formula = href.split("=");
  formula = formula[formula.length - 1];
  var href =
    "/payment/add?broadcaster=19&amount=" + discount + "&formula=" + formula;
  return href;
};
